<template>
  <div id="career">
    <page-content
      class="career-page-header"
      title="Work with Us"
      subtitle="We believe the best place to work is the place that provides interesting challenges, continuous personal development, a clear career path and encouraging colleagues."
    ></page-content>
    <benefit-section />
    <slider-section />
    <quote-section />
    <position-section />
  </div>
</template>
<script>
const PageContent = () => import("@/components/PageContent.vue");
const BenefitSection = () => import("./Section/BenefitSection.vue");
const SliderSection = () => import("./Section/SliderSection.vue");
const QuoteSection = () => import("./Section/QuoteSection.vue");
const PositionSection = () => import("./Section/PositionSection.vue");

export default {
  name: "Career",
  components: {
    PageContent,
    BenefitSection,
    SliderSection,
    QuoteSection,
    PositionSection,
  },
};
</script>
